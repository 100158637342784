import React from 'react';
import { navigate } from 'gatsby';
import { View, Text, ButtonWithLoading, Link } from '../../components/common';
import Layout from '../../components/layout';
import config from '../../config';
import { format } from 'date-fns';
import { colors } from '../../style';
import * as util from '../../account-util';

function first(arr) {
  if (arr.length > 0) {
    return arr[0];
  }
  return null;
}

class DeletePage extends React.Component {
  state = {
    email: '',
    error: null,
    success: false,
    deleting: false
  };

  componentDidMount() {
    if (!util.getTempId()) {
      navigate('/account/login');
    }
  }

  onDelete = async () => {
    if (this.state.deleting) {
      return;
    }
    this.setState({ deleting: true, error: null });

    let res = await fetch(config.endpoint + '/delete-account', {
      method: 'POST',
      body: JSON.stringify({ tempId: util.getTempId() }),
      headers: { 'Content-Type': 'application/json' }
    }).then(res => res.json());

    if (!util.handleError(res)) {
      if (res.status === 'error') {
        this.setState({ error: res.reason });
        this.setState({ deleting: false });
      } else {
        this.setState({ success: true });

        setTimeout(() => {
          navigate('/account/login');
        }, 2000);
      }
    }
  };

  render() {
    let { success, deleting, error } = this.state;

    return (
      <Layout>
        <View style={{ alignItems: 'center' }}>
          <View style={{ fontSize: 25, fontWeight: 600, marginBottom: 50 }}>
            Delete Account
          </View>

          {error && (
            <Text style={{ color: colors.r4, marginBottom: 30 }}>
              An error occurred. Please email{' '}
              <Link to="mailto:help@actualbudget.com">
                help@actualbudget.com
              </Link>
              .
            </Text>
          )}

          {success ? (
            <Text>Your account has been successfully deleted.</Text>
          ) : (
            <React.Fragment>
              <View style={{ fontSize: 20 }}>
                We'll miss you! Good luck on your endeavors.
              </View>

              <View style={{ width: 500, marginTop: 30 }}>
                This will delete your account permanently and remove all data
                from our servers. Your local budget data will stay exactly as it
                is on each device, but will not sync anymore. You can continue
                budgeting on a single device.
              </View>

              <ButtonWithLoading
                loading={deleting}
                style={{
                  borderWidth: 0,
                  backgroundColor: colors.r4,
                  color: 'white',
                  marginTop: 30
                }}
                onClick={this.onDelete}
              >
                Delete account
              </ButtonWithLoading>
            </React.Fragment>
          )}
        </View>
      </Layout>
    );
  }
}

export default DeletePage;
